import * as React from 'react';

import Layout from '../components/core/layout';
import Seo from '../components/core/seo';
import P from '../components/core/p';

const QuestionsPage = () => (
  <Layout>
    <Seo title="Questions" />
    <P>Some questions that I find interesting.</P>
  </Layout>
);

export default QuestionsPage;
